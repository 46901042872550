<template>
  <v-container
    id="sterling-reports-container"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <v-col cols="2">
        <v-autocomplete
          v-model="selectedAccessId"
          :disabled="loading"
          :items="accounts"
          color="primary"
          auto-select-first
          label="Select Account"
          item-text="name"
          item-value="accessId"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="selectedReport"
          :disabled="loading"
          :items="availableReports"
          color="primary"
          auto-select-first
          label="Select Account"
          item-text="label"
          item-value="value"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="selectedDateRange"
          :disabled="loading"
          :items="dateRange"
          color="primary"
          auto-select-first
          label="Date Range"
          item-text="label"
          item-value="value"
        />
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="from"
          v-model="search.fromModel"
          :return-value.sync="search.from"
          :disabled="loading || selectedDateRange != 'CUSTOM'"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search.from"
              label="Date From"
              prepend-icon="mdi-calendar"
              :disabled="loading || selectedDateRange != 'CUSTOM'"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="search.from"
            :max="search.to"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="search.fromModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.from.save(search.from)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="to"
          v-model="search.toModel"
          :return-value.sync="search.to"
          :disabled="loading || selectedDateRange != 'CUSTOM'"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search.to"
              :disabled="loading || selectedDateRange != 'CUSTOM'"
              label="Date To"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="search.to"
            :min="search.from"
            :max="maxDay"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="search.toModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.to.save(search.to)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="1"
        class="text-right"
      >
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          class="white--text btn-center"
          x-large
          @click="fetchReport()"
        >
          Search
          <v-icon
            dark
            right
          >
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="1"
        class="text-right"
      >
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="secondary"
          class="white--text btn-center"
          x-large
          @click="exportReport()"
        >
          Export
          <v-icon
            dark
            right
          >
            mdi-export
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      LOADING
    </v-row>
    <v-row v-else v-html="report">
    </v-row>
  </v-container>
</template>
<script>
import ezeReportService from '@/service/EzeReportsService'
import axios from 'axios'

export default {
  name: 'EzeReports',
  components: {
  },
  data: () => ({
    selectedAccessId: 0,
    selectedReport: '',
    selectedDateRange: 'NO_SELECTED', // default value
    availableReports: [
    ],
    accounts: [],
    dateRange: [{
      label: 'Last Business Day',
      value: 'LAST_BUSINESS_DAY'
    }, {
      label: 'This Week',
      value: 'THIS_WEEK'
    }, {
      label: 'Last Week',
      value: 'LAST_WEEK'
    }, {
      label: 'This Month',
      value: 'THIS_MONTH'
    }, {
      label: 'Last Month',
      value: 'LAST_MONTH'
    }, {
      label: 'Custom',
      value: 'CUSTOM'
    }],
    loading: false,
    search: {
      from: '',
      to: '',
      fromModel: false,
      toModel: false
    },
    report: ''
  }),
  watch: {
    async selectedAccessId (val) {
      this.selectedUserId = this.accounts.filter(i => i.accessId === val)[0].userId
    },
    selectedDateRange: function (val) {
      this.selectedDateRange = val
      const today = new Date()
      const from = new Date()
      const to = new Date()
      switch (val) {
        case 'LAST_BUSINESS_DAY':
          // 0 - S => -2
          // 1 - M => -3
          // 2 - T => -1
          // 3 - W => -1
          // 4 - T => -1
          // 5 - F => -1
          // 6 - S => -1
          from.setDate(today.getDate() - (today.getDay() === 0 ? 2 : (today.getDay() === 1 ? 3 : 1)))
          to.setDate(today.getDate() - (today.getDay() === 0 ? 2 : (today.getDay() === 1 ? 3 : 1)))
          break
        case 'THIS_WEEK':
          from.setDate(today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1))
          break
        case 'LAST_WEEK':
          from.setDate(today.getDate() - (today.getDay() === 0 ? 13 : today.getDay() + 6))
          to.setDate(today.getDate() - (today.getDay() === 0 ? 7 : today.getDay()))
          // to.setDate(today.getDate() - (today.getDay() === 0 ? 7 : 8 - today.getDay()))
          break
        case 'THIS_MONTH':
          from.setMonth(today.getMonth(), 1)
          // to.setMonth(today.getMonth() + 1, 0) - NOTE - set last day from the months today
          break
        case 'LAST_MONTH':
          from.setMonth(today.getMonth() - 1, 1)
          to.setMonth(today.getMonth(), 0)
          break
        case 'CUSTOM':
        default:
      }

      this.search.from = this.formatDate(from)
      this.search.to = this.formatDate(to)
    }
  },
  computed: {
    maxDay: () => {
      return new Date().toISOString().substr(0, 10)
    }
  },
  beforeMount () {
    this.fetchAccounts()
    this.fetchAvailableReports()
    this.selectedDateRange = 'LAST_BUSINESS_DAY'
  },
  methods: {
    async fetchAccounts () {
      this.loading = true
      const response = await ezeReportService.fetchAccounts()
      if (response.status === 200) {
        this.accounts = response.data
        this.selectedAccessId = this.accounts[0].accessId
      }
      this.loading = false
    },
    async fetchAvailableReports () {
      this.loading = true
      const response = await ezeReportService.fetchAvailableReports()
      if (response.status === 200) {
        this.availableReports = response.data
        this.selectedReport = this.availableReports[0].value
      }
      this.loading = false
    },
    async fetchReport () {
      this.loading = true

      // request
      let query = `report-type=${this.selectedReport}`
      if (this.search.from) {
        query += `&from=${new Date(this.search.from).getTime()}`
      }
      if (this.search.to) {
        query += `&to=${new Date(this.search.to).getTime()}`
      }
      if (this.selectedAccessId) {
        query += `&accessId=${this.selectedAccessId}`
      }
      axios.get(`/api/v1/reports/eze/fetch-report?${query}`)
        .then(r => {
          this.report = r.data
          this.loading = false
        })
        .catch(e => {
          this.report = ''
          this.loading = false
        })
    },
    async exportReport () {
      const selectReportLabels = this.availableReports.filter(item => item.value === this.selectedReport)
      const reportName = selectReportLabels.length === 0 ? 'Unknown' : selectReportLabels[0].label
      const filename = `${reportName} ${this.search.from}-${this.search.to}.csv`
      const uri = `data:application/vnd.ms-excel;filename=${filename};base64,`
      const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets>'
      const uriCsv = 'data:text/csv;charset=utf-8;filename=${filename};base64,'
      const templateend = '</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>'
      const body = '<body>'
      const tablevar = '<table>{table'
      const tablevarend = '}</table>'
      const bodyend = '</body></html>'
      const worksheet = '<x:ExcelWorksheet><x:Name>'
      const worksheetend = '</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>'
      const worksheetvar = '{worksheet'
      const worksheetvarend = '}'
      const base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      const format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p] }) }
      let wstemplate = ''
      let tabletemplate = ''

      const tables = document.getElementsByClassName('report')

      for (var i = 0; i < tables.length; ++i) {
        wstemplate += worksheet + worksheetvar + i + worksheetvarend + worksheetend
        tabletemplate += tablevar + i + tablevarend
      }

      const allTemplate = template + wstemplate + templateend
      const allWorksheet = body + tabletemplate + bodyend
      const allOfIt = allTemplate + allWorksheet

      const ctx = {}
      for (let j = 0; j < tables.length; ++j) {
        ctx['worksheet' + j] = j
      }

      for (let k = 0; k < tables.length; ++k) {
        ctx['table' + k] = tables[k].innerHTML
      }

      // window.location.href = uri + base64(format(allOfIt, ctx))


     let csv = [];
        const rows = document.querySelectorAll("table tr");
        for (const row of rows.values()) {
            const cells = row.querySelectorAll("td, th");

            const rowText = Array.from(cells).map(cell => cell.innerText.replace(/,/g, '').replace('(', '-').replace(')', ''));
            if (rowText[0] === 'S' && rowText[1] === 'M') {
                //remove calendar block from the export
                break;
            }
            csv.push(rowText.join(','));
        }

        var csvData = "data:application/csv;charset=utf-8;filename=${filename}," + encodeURIComponent(csv.join('\n'));


      let link = document.createElement('a')
      link.href = csvData
      link.download = filename
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      link = null
    },
    formatDate (date) {
      const year = date.getFullYear()
      let month = date.getMonth()
      const day = date.getDate()

      month = month + 1
      return [
        year < 9 ? '0' + year : year,
        month < 10 ? '0' + month : month,
        day < 10 ? '0' + day : day].join('-')
    }
  }
}
</script>
<style>

/* Tables / Report Body - Used in all reports
-------------------------------------------------------------- */
html
{
    width: 100%;
    overflow-x: auto;
}

TABLE.report {
  background-color: #fff;
  border: 1px solid #a0aabe;
  width: 100%;
  margin-top: 2rem;
  border-radius: 5px;
  /*font-family: cursive;*/
  letter-spacing: 0.75px;
  font-family: Verdana;
  font-weight: 300;
}

TABLE.report thead th {
  text-align: left;
  padding-left: 16px;
  background: #f5f5f5 none;
  color: #a50d0d;
  font: bold 14px/30px Verdana, Arial, sans-serif;
}

TABLE.report TD, TABLE.report TH {
  padding: 2px;
  white-space: nowrap;
  vertical-align: middle;
}

TABLE.report TH {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

TABLE.report TD {
  text-align: right;
  background-color: #e5e5e5;
  vertical-align: top;
  padding: 1px;
  padding-right: 1px;
}

TABLE.report TD.text, TABLE.report TH.text {
  text-align: right;
}

TABLE.report TD.exacttext {
  text-align: left;
  white-space: pre;
}

TABLE.report TD.massUpdateForm, TABLE.report TH.massUpdateForm {
  text-align: left;
  vertical-align: middle;
}

TABLE.report TD.wrappingtext{
  text-align: left;
  white-space: normal;
}

TABLE.report TD.exactflag {
  text-align: center;
  white-space: pre;
}

TABLE.report TD.time, TABLE.report TD.flag {
  text-align: center;
}

TABLE.report TD.border {
  background-color: #a0aabe;
  padding: 0;
  height: 1px;
}

TABLE.report TD.spacer {
  background-color: #333;
  padding: 0;
  height: 1px;
}

TABLE.report TR.rule TD {
  height: 2px;
  padding: 0;
  margin: 0;
}

TABLE.report TR.summary TH {
  background-color: #d5d5e5;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
}

TABLE.report TR.detail TH {
  background-color: #e5e5e5;
}

TABLE.report TR.subtotal TD {
  font-weight: bold;
}

TABLE.report TR.total TH {
  background-color: #ccc;
}

TABLE.report TR.total TD {
  background-color: #d5d5d5;
  font-weight: bold;
  font-size: 1rem;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
}

TABLE.report TR.sectionSeparator TD {
  background-color: #fff;
  color: #036;
  padding-top: 1px;
  text-align: left;
  padding-bottom: 1px;
  padding-left: 1px;
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
}

TABLE.report TR.tab TD {
  border-left: 0 solid #fff;
  background: #fff;
}

TABLE.report TR.tab TD.activeTab {
  background: #369;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

TABLE.report TD.discrepancy {
  background-color: #fce9ea;
}

TABLE.report TD.noDiscrepancy {
  background-color: #e5eedf;
}

TR.loss TD, TABLE.report TD.loss {
  background-color: #fce9ea;
}

TABLE.report TD.merge-conflict{
  background-color: #fce9ea;
  font-weight: bold;
}

TR.win TD, TR.currentPlan TD, TABLE.report TD.win {
  background-color: #e5eedf;
}

TR.wash TD {
  background-color: #f5f5f5;
}

TR.weekend TD, TR.disabled TD {
  background-color: #FCE9EA;
}

TR.incomplete TD, TR.noPlan TD {
  background-color: #f9d4d8;
}

TR.enabled TD {
  background-color: #e5eedf;
}

TD.redBoldText {
  color: #c03;
  font-weight: bold;
}

TR.inactive TD, TR.inactive-summary TD {
  font-style: italic;
}

TABLE.report TD.bad {
  background-color: #ffA5A5;
}

TABLE.report TD.good {
  background-color: #e5eedf;
}

TABLE.report text {
  text-align: right;
}

TABLE.autowidth {
  width: auto;
}

TABLE.report TR.transferAdjustment TD {
  background-color: #DDE6EE;
}

Table span.tooltip {
  display : none;
}

TABLE.report a {
  color: black !important;
}

</style>
